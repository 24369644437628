<script lang="ts" setup>
import { MessageModalType } from '~/stores/modal';

const TEST_ID = 'message-modal';

const { t } = useI18n();
const modalStore = useModalStore();

const modal = computed(() => modalStore.messageModalList[0] || {});

const ariaTitle =
  modal.value.type &&
  {
    [MessageModalType.success]: t('type.success'),
    [MessageModalType.error]: t('type.error'),
    [MessageModalType.info]: t('type.info'),
  }[modal.value.type];

const onCloseLatestModel = () => modalStore.closeMessageModal()?.onClose?.();
</script>

<template>
  <BaseModal
    :visible="!!modal.key"
    @update:visible="onCloseLatestModel"
    container-class="baseMessageModal"
    :aria-title="ariaTitle"
    :aria-describedby="modal.title"
    :z-index="102"
    :data-test-id="TEST_ID"
  >
    <BaseToggleTransition
      :state="modal.key"
      class="baseMessageModal__toggle"
      content-class="baseMessageModal__content"
    >
      <SvgoSuccess
        v-if="modal.type === MessageModalType.success"
        filled
        class="baseMessageModal__icon"
      />
      <SvgoError
        v-if="modal.type === MessageModalType.error"
        filled
        class="baseMessageModal__icon"
      />
      <p class="baseMessageModal__message">{{ modal.title }}</p>
      <BaseButton
        @click="onCloseLatestModel"
        dark
        outlined
        size="s"
        class="baseMessageModal__action"
        :data-test-id="`${TEST_ID}-apply`"
      >
        {{ modal.action }}
      </BaseButton>
    </BaseToggleTransition>
  </BaseModal>
</template>

<i18n>
ru:
  type:
    success: Информация об успехе
    error: Информация об ошибке
    info: Общая информация

en:
  type:
    success: Success information
    error: Error information
    info: Common information
</i18n>

<style lang="scss">
.baseMessageModal {
  max-width: 532px;
  width: 100%;
  padding: 76px 64px 48px;

  @include mq('sm') {
    padding: 102px 16px 48px;
  }

  &__toggle {
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    display: block;
    margin-bottom: 34px;
    width: 83px;
    height: 83px;

    @include mq('sm') {
      margin-bottom: 32px;
    }
  }

  &__message {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 36px;
    white-space: pre-wrap;

    @include mq('sm') {
      margin-bottom: 64px;
    }
  }

  &__action {
    @include mq('sm') {
      width: 100%;
    }
  }
}
</style>
